.MobileNavBar {
  width: 100%;
  background: #191B24;
  box-shadow: 0 0 2px 0 #C1C1FF;
}

.menus {
  display: none;
  color: #999999;
  font-size: 1.2em;
  cursor: pointer;
}

.item {
  padding: 10px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.item_active {
  font-weight: bold;
  color: white;
  font-size: 1.1em;
}

@media screen and (width <= 600px) {
  .menus {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}