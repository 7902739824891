.ResponsiveNavBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
}

.menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #999999;
  font-size: 1.2em;
  gap: 2em;
  cursor: pointer;
}

.title_active {
  font-size: 1.1em;
  font-weight: bold;
  color: white;
}

.link {
  color: inherit;
  text-decoration: none;
}

@media screen and (width <= 600px) {
  .menu {
    display: none;
  }
}